import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Container, Row, Col} from 'react-bootstrap';

import {usePageContent} from 'src/functionality/content-provider';
import usePeople from 'src/functionality/people-provider';
import {theme} from 'src/styles/theme';
import Hero from 'src/images/placeholder.jpg';
import {H3, H4} from 'src/components/functional/headings';

import {useFeatureFlags} from 'src/functionality/flags-provider';
import {useLocation} from '@reach/router';
import {getResource} from 'src/functionality/resources-provider';
import Page from 'src/components/functional/page';
import Tile from 'src/components/functional/tile';
import PlainHeroSection from 'src/components/functional/hero-section-plain';
import ContentBlock from 'src/components/functional/content-block';
import styled from 'styled-components';
import Spacer from 'src/components/graphical/spacer';
import Strip from 'src/components/functional/strip';

const CategoryCol = styled(Col)`
  text-align: center;
  & > button {
    text-decoration: none;
    color: ${(props) => props.selected ? theme.primary : theme.muted};
    font-size: 1.3em;
    border: none;
    background: none;
  }
  & > button:hover {
    color: ${(props) => props.selected ? theme.primary : theme.gray};
  }
  & > button:active {
    color: ${(props) => props.selected ? theme.primary : theme.darkPrimary};
  }
`;

const PersonTile = styled(Tile)`
  transition: all 1s ease;
  &:hover{
    cursor: pointer;
  }
  &:hover a > div{
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  }
`;

const PersonPopup = styled.div`
  box-shadow: 0px 10px 27px 17px rgba(0,0,0,0.125);
  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
  width: min(95vw, 50em);
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: ${theme.white};
  padding: 1.5em;
  padding-top: 0;
  z-index: 1500;
  overflow-y: scroll;
  
  & .close:hover{
    cursor: pointer;
    color: ${theme.danger}
  }
  & .close{
    font-size: 3rem;
    color: ${theme.muted};
    line-height: 1.5em;
  }
  & .close-header{
    display: flex;
    justify-content: end;
    padding: 0.25em 0;
  }
`;

const PersonPopupFocusBG = styled.div`  
  position: fixed;
  background-color: #00000040;
  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1010;
`;

const ProfileImg = styled.img`
  height: 10em;
  width: 10em;
  border-radius: 50%;
`;

const GovernanceSection = styled.section`
  margin: 4vh 0;
`;

// eslint-disable-next-line max-len
const ORG_CHART = 'resources/Policies-plans-and-procedures/Organisational-Chart-October-2023.pdf';

const ManagersAndAdvisorsPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const people = usePeople();
  const [person, setPerson] = useState(null);
  const [selectedCategory, setSelectedCategory] =
    useState('The Board');
  const flags = useFeatureFlags();
  return (
    <Page white={true}>
      <PlainHeroSection
        breadcrumbs = {true}
        bgImage={Hero}
        content={content}
        tag="MA01"
        backgroundColor={theme.white}
        headingSize="md"
      />
      {person &&
        <PersonPopup visible={person !== null}>
          <div className="close-header">
            <span className="close" onClick={() => setPerson(null)}>
              &times;
            </span>
          </div>
          <ProfileImg
            src={person.profileUrl}
            alt={`${person.firstName} ${person.lastName} profile picture`}
          />
          <H4 display={5} className="text-primary" noUnderline={true}>
            {person.firstName}<br/>{person.lastName}
          </H4>
          <em>{person.title}</em>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={{[person.content?.contentTag]: person.content}}
            tag={person.content?.contentTag}
          />
        </PersonPopup>
      }
      <PersonPopupFocusBG visible={person !== null}/>
      <Container>
        <GovernanceSection>
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='MA05a'
          />
          <hr />
          <Row>
            {[
              'The Board',
              'Executive Managers',
              'Lifetime Friends',
            ].map((category, key) =>
              <CategoryCol
                sm={12}
                md={4}
                key={key}
                selected={selectedCategory === category}
              >
                <button onClick={() => setSelectedCategory(category)}>
                  {category}
                </button>
              </CategoryCol>,
            )}
          </Row>
          <hr />
          <Spacer height={2}/>

          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag={{
              'The Board': 'MA03a',
              'Executive Managers': 'MA02a',
              'Lifetime Friends': 'MA04',
            }[selectedCategory]}
          />
        </GovernanceSection>
        {flags.ManagersAndAdvisors_EnableProfiles &&
        <Row>
          {people && people.map((person, key) => {
            if (person.tags.items.map((tag) => tag.tag.tag)
                .includes(selectedCategory.slice(0, -1))) {
              return (
                <PersonTile
                  isRounded={true}
                  type='picture'
                  key={key}
                  src={person.profileUrl}
                  title={
                    `${person.firstName} ${person.lastName}, ${person.title}`}
                  onClick={() => setPerson(person)}
                >
                  {person.description}
                </PersonTile>
              );
            }
          })}
        </Row>
        }
        <Strip
          bg={theme.bodyBg}
          padding="2vh"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'middle',
            textAlign: 'center',
          }}
        >
          <ContentBlock
            header={(props) => <H3 {...props}/>}
            content={content}
            tag='MA06'
          />
          <div>
            <Tile
              isRounded={true}
              type='document'
              onClick={() =>
                getResource(ORG_CHART)
                    .then((url) => window.open(url))}
              title="Organisational chart"
            />
          </div>
        </Strip>
        <Spacer height={4}/>
      </Container>
    </Page>
  );
};

ManagersAndAdvisorsPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.any,
  }),
};

export default ManagersAndAdvisorsPage;
