import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listPeople} from 'src/graphql/content';

/**
 * Returns people
 * @return {object} an object with multiple people
 */
export default function usePeople() {
  const [people, setPeople] = useState();

  useEffect(() => {
    if (!people) {
      (async () => {
        const data = await API.graphql({
          query: listPeople,
        });
        setPeople(data.data.listPeople.items);
      })();
    }
  });
  return (people);
}
